import { useEffect, useState } from "react";
import { EventType, ModelSortDirection } from "../../API";
import { Datum, DatumType, Eventlog, Node } from "../../model/database";
import { Result } from "./result";
import { useDatabase, useUpdateRevision } from "./use-database";

export type DatumInfo = {
    node: Node;
    datum: Datum;
    datumType?: DatumType;
    eventlog?: Eventlog;
};

/**
 * 機器情報をIndexに従ってソートする処理
 * @param NodeInfo
 * @returns ソートされた機器情報配列-
 */
function sortNodeInfos(DatumInfo: DatumInfo[]): DatumInfo[] {
    return DatumInfo.sort((a: DatumInfo, b: DatumInfo) => {
        if (a.node.treeIndex == null) {
            return 1;
        }
        if (b.node.treeIndex == null) {
            return -1;
        }
        return a.node.treeIndex - b.node.treeIndex;
    });
}

/**
 * Node配列から各NodeのDatumType、Datumの配列を取得する
 * @param nodes Node配列
 * @param options オプション
 * @returns
 */
export function useWarningDatumInfos(
    nodes?: readonly Node[],
): Result<DatumInfo[]> {
    const datumTable = useDatabase().datums;
    const datumTypeTable = useDatabase().datumTypes;
    const eventlogTable = useDatabase().eventlogs;
    // const nodeTypeTable = useDatabase().nodeTypes;
    const datumRevision = useUpdateRevision(datumTable);
    const datumTypeRevision = useUpdateRevision(datumTypeTable);
    // const nodeTypeRevision = useUpdateRevision(nodeTypeTable);
    const [result, setResult] = useState<Result<DatumInfo[]>>({});

    useEffect(() => {
        if (!nodes) {
            return undefined;
        }
        const ac = new AbortController();
        const getDatumInfo = async (
            node: Node,
            signal: AbortSignal,
        ): Promise<DatumInfo[]> => {
            const datumTypes = await datumTypeTable.findGreedily(
                {
                    index: "listDatumTypesByNodeTypeId",
                    nodeTypeId: node.typeId,
                },
                { signal },
            );

            const datums = await datumTable.findGreedily(
                {
                    index: "listDatumsByNodeIdTypeId",
                    nodeId: node.id,
                },
                { signal },
            );

            // datums.data は順序が不定なので、順序がきちんとしている datumTypes.data から
            // map して順序を整える
            const data = datumTypes
                .map((dt) => datums?.find((d) => d.typeId === dt.id))
                .filter((d): d is Datum => d != null)
                .filter((datum) => datum.status !== 0);

            // 異常発生中の Datum がいつ、異常になったかを知るために
            // Datumを指定して Eventlog を取得する
            const result = Promise.all(
                data.map(async (datum) => {
                    const { items: eventlogs } = await eventlogTable.find({
                        index: "listEventlogsByDatumId",
                        datumId: datum.id,
                        limit: 1,
                        sortDirection: ModelSortDirection.DESC,
                        filter: {
                            or: [
                                {
                                    type: {
                                        eq: EventType.Recovered,
                                    },
                                },
                                {
                                    type: {
                                        eq: EventType.Failure,
                                    },
                                },
                            ],
                        },
                    });

                    return {
                        node,
                        datum,
                        datumType: datumTypes.find(
                            (datumType) => datumType.id === datum.typeId,
                        ),
                        eventlog:
                            eventlogs.length > 0 &&
                            eventlogs[0].type === EventType.Failure
                                ? eventlogs[0]
                                : undefined,
                    };
                }),
            );
            return result;
        };

        Promise.all(nodes.map((node) => getDatumInfo(node, ac.signal))).then(
            (result) => {
                if (ac.signal.aborted) {
                    return;
                }
                setResult({ data: sortNodeInfos(result.flat()) });
            },
            (error) => {
                if (ac.signal.aborted) {
                    return;
                }
                setResult({ error });
            },
        );

        return () => {
            ac.abort();
        };
    }, [
        datumRevision,
        datumTable,
        datumTypeRevision,
        datumTypeTable,
        eventlogTable,
        nodes,
    ]);

    return result;
}
